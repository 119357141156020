import React, { useState } from "react";
import { IAjaxResponseBase } from '../../utils/types';


export interface IFormValues {
    name: string;
    phone: string;
    email: string;
    message: string;
}

export interface IFormPageContext {
    initialValues: IFormValues;
    onFormSubmit: (values: IFormValues) => void;
    serverErrors: Array<string>;
    submitted: boolean;
    successMessage: string;
}

const defaultInitialValues: IFormValues = {
    name: "",
    phone: "",
    email: "",
    message: ""
}

const defaultContext: IFormPageContext = {
    initialValues: defaultInitialValues,
    serverErrors: [],
    submitted: false,
    onFormSubmit: (values) => Promise.resolve({ success: true }),
    successMessage: "Your request was successfully submitted. An agent wil contact you shortly."
};

export const FormPageContext = React.createContext<IFormPageContext>(defaultContext);

interface IFormContextProps {
    initialValues: IFormValues;
    onSubmit: (values: IFormValues) => Promise<IAjaxResponseBase>;
}

const ContactUsPageContextProvider: React.FC<IFormContextProps> = (props) => {
    const [serverErrors, setServerErrors] = useState<Array<string>>([]);
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const onFormSubmit = (values: IFormValues) => {
        props.onSubmit(values).then((value) => {
            setSubmitted(value.success);            
            if (!value.success) {
                setSuccessMessage("");
                setServerErrors(value.serverErrors);
            }
            else {
                setSuccessMessage(value.message);
                setServerErrors([]);
            }
        }).catch(err => {
            console.log(err);
            setServerErrors(["An error occurred while submitting the form. Please try again later"]);
        });
    }

    return (
        <FormPageContext.Provider value={{
            initialValues: props.initialValues,
            serverErrors,
            submitted,
            onFormSubmit,
            successMessage
        }}>
            {props.children}
        </FormPageContext.Provider>
    );
};

export default ContactUsPageContextProvider;