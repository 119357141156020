import queryString from "query-string"
import { IAppliedFilters, getAppliedFilters } from '../components/filters/property-filters';

interface IResultsPageProps {
    appliedFilters: IAppliedFilters;
}


const initResultsPage = (pageProps: IResultsPageProps) => {

    const sortBy = document.getElementById("sortBy") as HTMLSelectElement;

    if (sortBy) {
        const { appliedFilters } = pageProps;
        const { baths, beds, maxPrice, minPrice, selectedPropertyTypes, selectedShapes, selectedSortOption } = appliedFilters;
        sortBy.addEventListener("change", () => {
            if (selectedSortOption !== sortBy.value) {
                location.search = queryString.stringify(getAppliedFilters(selectedShapes, selectedPropertyTypes, minPrice, maxPrice, beds, baths, sortBy.value), { arrayFormat: "comma" });
            }
        })
    }
}

export default initResultsPage;     
