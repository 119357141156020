import React from "react";

export interface IAgentContactInfo {
    agentId: number;
    tel: string;
    cell: string;
}

export interface IAgentContactInfoContext {
    agentContactInfo: IAgentContactInfo | null;
    numbersShown: boolean;
}

export const initialValues: IAgentContactInfoContext = {
    agentContactInfo: null,
    numbersShown:false
};

const AgentContactInfoContext = React.createContext<IAgentContactInfoContext>({ ...initialValues });

export default AgentContactInfoContext;