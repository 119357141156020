
import { getDataAttribute } from './portal-utils';

interface LazyLoaderConfig {
    lazyLoadClass: string;
    throttleTimeout: number;
    logErrors: boolean;
    sizesAttribute: string;
    threshold: number; // Number between 0 and 1 representing how much of the pbject is revealed before the intersection observer is triggered.
    rootMargin: string | undefined;
}

const defaultConfig: LazyLoaderConfig = {
    lazyLoadClass: "lazyload",
    throttleTimeout: 20,
    logErrors: false,
    sizesAttribute: "sizes",
    rootMargin: undefined,
    threshold: 0,
};

export const initLazyLoading = (config?: Partial<LazyLoaderConfig>) => {

    const settings: LazyLoaderConfig = {
        ...defaultConfig,
        ...config
    };

    if ("IntersectionObserver" in window) {
        const lazyLoadElements = Array.from(document.getElementsByClassName(settings.lazyLoadClass) as HTMLCollectionOf<HTMLElement>);
        const imageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const element = entry.target as HTMLElement;
                    load(element, settings);
                    observer.unobserve(element);
                }
            });
        },
            {
                rootMargin: settings.rootMargin,
                threshold: settings.threshold
            });

        lazyLoadElements.forEach((element) => {
            imageObserver.observe(element);
        });
    }
    else {
        let lazyLoadThrottleTimeout: number;

        const lazyLoad = () => {

            if (lazyLoadThrottleTimeout) {
                clearTimeout(lazyLoadThrottleTimeout);
            }

            lazyLoadThrottleTimeout = window.setTimeout(() => {

                const lazyLoadElements = Array.from(document.getElementsByClassName(settings.lazyLoadClass) as HTMLCollectionOf<HTMLElement>);


                if (lazyLoadElements.length === 0) {
                    document.removeEventListener("scroll", lazyLoad);
                    window.removeEventListener("resize", lazyLoad);
                    window.removeEventListener("orientationchange", lazyLoad);
                }

                const scrollTop = window.pageYOffset;

                lazyLoadElements.forEach((element, i) => {

                    const { top } = element.getBoundingClientRect();

                    if (top < (window.innerHeight + scrollTop) && top > scrollTop) {
                        if (element.classList.contains(settings.lazyLoadClass)) {
                            load(element, settings);
                        }
                    }
                });

            }, settings.throttleTimeout);
        }

        const windowRef = window as Window
        if (!windowRef) {
            console.error("DOM window interface in not available");
        } else {
            windowRef.addEventListener("load", lazyLoad);
            windowRef.addEventListener("resize", lazyLoad);
            windowRef.addEventListener("orientationchange", lazyLoad);
        }
        document.addEventListener("scroll", lazyLoad);
    }

}

const load = (element: HTMLElement, config: LazyLoaderConfig) => {

    if (element.tagName === "IMG") {

        const onLoad = (e: Event) => {
            const currImg = e.currentTarget as HTMLImageElement;
            currImg.classList.remove(config.lazyLoadClass);
            currImg.removeEventListener("load", onLoad);
        }

        const img = element as HTMLImageElement;

        const originalSource = img.src;

        img.addEventListener("load", onLoad);

        const onError = (e: ErrorEvent) => {
            const currImg = e.currentTarget as HTMLImageElement;

            // Set image source back to the original to prevent 404 from emptying container
            currImg.src = originalSource;
            if (config.logErrors && e.error) {
                console.log(e.error);
            }
            // Remove item from lazyload list
            currImg.classList.remove(config.lazyLoadClass);
            // Prevent infinite error loop
            currImg.removeEventListener("error", onError);
        }

        img.addEventListener("error", onError);

        if (img.parentElement && img.parentElement.tagName === "PICTURE") {
            const sources = Array.from(img.parentElement.querySelectorAll<HTMLSourceElement>("source"));
            if (sources) {
                sources.forEach(source => {
                    const srcSet = getDataAttribute(source, "srcset");
                    source.srcset = srcSet;
                });
            }
        }

        const src = getDataAttribute(img, "src");
        const srcUrl = hostnameToUrl(src);
        if (srcUrl !== null) {
            img.src = srcUrl.toString();
        }

        const srcSet = getDataAttribute(img, "srcset");
        if (srcSet) {
            img.srcset = srcSet;
        }

        img.classList.add("fade-in");

    }
    else {
        const bg = getDataAttribute(element, "background");
        if (bg) {
            ensureImageLoad(element, bg, config);
        }
        const bgSet = getDataAttribute(element, "bgset");
        if (bgSet) {
            const testImage = createPicture(bgSet, element, config);
            if (testImage) {
                testImage.onload = () => {
                    element.style.backgroundImage = `url(${testImage.currentSrc})`;

                    //// Clean up picture element after background has been set.
                    //if (testImage.parentElement && testImage.parentElement.tagName === "PICTURE") {
                    //    element.removeChild(testImage.parentElement);
                    //}                    
                }
            }
        }
        element.classList.add("fade-in");
    }


}

const createPicture = (setsAttr: string, element: HTMLElement, config: LazyLoaderConfig): HTMLImageElement => {
    const regWhite = /\s+/g;
    const regSplitSet = /\s*\|\s+|\s+\|\s*/g;
    const regSource = /^(.+?)(?:\s+\[\s*(.+?)\s*\])(?:\s+\[\s*(.+?)\s*\])?$/;

    const picture = document.createElement("picture");
    const img = document.createElement("img");
    const sizes = getDataAttribute(element, config.sizesAttribute);

    const sets = setsAttr.replace(regWhite, " ").split(regSplitSet);
    if (!(window as any).HTMLPictureElement) {
        if (sets.length > 0) {
            const sourceString = sets[sets.length - 1];
            const match = sourceString.match(regSource);
            if (match) {
                ensureImageLoad(element, match[1], config);
            }
            else {
                ensureImageLoad(element, sourceString, config);
            }
        }

    }
    else {
        picture.style.display = "none";

        sets.forEach(set => {
            const match = set.match(regSource);
            const source = document.createElement("source");

            if (sizes && sizes != "auto") {
                source.setAttribute("sizes", sizes);
            }

            if (match) {
                source.setAttribute("srcset", match[1]);

                setTypeOrMedia(source, match[2]);
                setTypeOrMedia(source, match[3]);
            } else {
                source.setAttribute("srcset", set);
            }

            picture.appendChild(source);
        });

        if (sizes) {
            img.setAttribute("sizes", sizes);
            element.removeAttribute("data-sizes");
            element.removeAttribute("sizes");
        }

        picture.appendChild(img);

        element.appendChild(picture);


    }
    return img;
}

const setTypeOrMedia = (source: HTMLSourceElement, match: string) => {
    const regType = /^\s*\(*\s*type\s*:\s*(.+?)\s*\)*\s*$/;
    if (match) {
        const typeMatch = match.match(regType);
        if (typeMatch && typeMatch[1]) {
            source.setAttribute('type', typeMatch[1]);
        } else {
            source.setAttribute('media', match);
        }
    }
};

const ensureImageLoad = (element: HTMLElement, bg: string, config: LazyLoaderConfig) => {
    const testImage = document.createElement("img");
    testImage.src = bg;
    testImage.onload = () => {
        element.style.backgroundImage = `url('${bg}')`;
        element.classList.remove(config.lazyLoadClass);
    }
}

const hostnameToUrl = (hostname: string | undefined | null) => {
    if (hostname == null ||
        hostname == '' ||
        hostname == undefined ||
        hostname == 'undefined') {
        return null;
    }

    var currentUrl = new URL(window.location.href);

    try {
        var srcUrl = new URL(hostname);
        srcUrl.protocol = currentUrl.protocol; // avoid https/http loading
        return srcUrl;
    }
    catch {
        // if not valid URL, then we assume hostname
    }

    currentUrl.hostname = hostname;
    return currentUrl;
}