import React from "react";

import LazyPreloader from "./lazy-preloader";

interface ISlideProps {
    backgroundImage?: string;
    src?: string;
    srcSet?: string;
    lazyLoad?: boolean;
    alt: string
}

const Slide: React.FC<ISlideProps> = (props) => {
    const { backgroundImage, src, srcSet, lazyLoad, alt } = props;
    if (lazyLoad) {
        if (backgroundImage) {
            return (
                <div className="swiper-slide">
                    <div className="swiper-lazy" data-background={backgroundImage}>
                        <LazyPreloader />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="swiper-slide">
                    {srcSet ?
                        <img data-srcset={srcSet} alt={alt} className="swiper-lazy" /> :
                        <img data-src={src} alt={alt} className="swiper-lazy" />
                    }
                    <LazyPreloader />
                </div>
            );
        }
    }
    else {
        if (backgroundImage) {
            return (
                <div className="swiper-slide" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
            );
        }
        else {
            return (
                <div className="swiper-slide">
                    {srcSet ?
                        <img srcSet={srcSet} alt={alt} /> :
                        <img src={src} alt={alt} />
                    }
                </div>
            );
        }
    }

}

export default Slide;