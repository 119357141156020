import React from "react";
import classNames from "classnames";

interface IPreloaderProps {
    mode?: "dark" | "light";
}

const LazyPreloader: React.FC<IPreloaderProps> = ({ mode }) => {
    return (
        <div className={classNames("swiper-lazy-preloader", mode === "light" && "swiper-lazy-preloader-white")}></div>
    );
}

LazyPreloader.defaultProps = {
    mode: "light"
}

export default LazyPreloader;