import React, { useRef } from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'swiper-container': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
            'swiper-slide': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
        }
    }
}


import { SwiperOptions } from 'swiper/types';
import { register } from 'swiper/element/bundle';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./gallery.scss";
import Slide from './slide';

register();

export interface GalleryImage {
    desktopImage: string;
    mobileImage: string;
    thumbnail?: string;
    alt: string;
    id: string;
}

export interface GalleryProps {
    useBackgroundImage?: boolean;
    images: Array<GalleryImage>;
    themeColor: string;
    showNextPrev?: boolean;
    showPagination?: boolean;
    lazyLoad?: boolean;
    loop?: boolean;
}

const Gallery: React.FC<GalleryProps> = (props) => {
    const { images, lazyLoad, useBackgroundImage, loop } = props;

    const swiperEl = useRef<HTMLDivElement>(null);


    const swiperOptions: SwiperOptions = {
        //preloadImages: !lazyLoad,
        lazyPreloadPrevNext: lazyLoad ? 1 : undefined,
        centeredSlides: true,
        loop: loop,
        keyboard: true,
        modules: [Navigation, Pagination]
    };
    if (props.showNextPrev) {
        swiperOptions.navigation = {
            nextEl: "#swiper-button-next",
            prevEl: "#swiper-button-prev"
        }
    }
    if (props.showPagination) {
        swiperOptions.pagination = {
            el: "#swiper-pagination",
            type: "bullets",
            clickable: true,
        }
    }
    if (props.lazyLoad) {
        swiperOptions.lazyPreloadPrevNext = 1
        swiperOptions.lazyPreloaderClass = "swiper-lazy-preloader"
    }

    let loadingAttr = lazyLoad ? { "loading": "lazy" } : {};

    return (
        <swiper-container ref={swiperEl} className="swiper-container gallery-top" {...swiperOptions}>
            {
                images.map((image, i) => {
                    if (useBackgroundImage) {
                        return (<Slide key={image.id} lazyLoad={lazyLoad} backgroundImage={image.desktopImage} alt={image.alt} >
                        </Slide>);
                    }
                    else {
                        return (<Slide key={image.id} src={image.mobileImage} srcSet={`${image.mobileImage} 640w, ${image.desktopImage} 1024w`} alt={image.alt} {...loadingAttr} >
                        </Slide>)
                    }
                })
            }
            {
                props.showPagination &&
                <div id="swiper-pagination" className="swiper-pagination"></div>
            }
            {
                props.showNextPrev &&
                (<>
                    <div id="swiper-button-next" className="swiper-button-next swiper-button-theme">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
                            <path d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z" fill={props.themeColor} />
                        </svg>
                    </div>
                    <div id="swiper-button-prev" className="swiper-button-prev swiper-button-theme">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
                            <path d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z" fill={props.themeColor} />
                        </svg>
                    </div>
                </>)
            }
        </swiper-container>
    );
}

Gallery.defaultProps = {
    themeColor: "#fff"
};

export default Gallery;