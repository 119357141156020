import React, { useContext } from "react";


import AgentContactInfoContext from "./agent-contact-info-context"
import { formatPhoneNumbers } from "../../../utils/portal-utils";

import "./contact-info-manager.scss";


interface ContactInfoManagerProps {
    onGetContactDetails: () => void;
}



const ContactInfoManager: React.FC<ContactInfoManagerProps> = ({ onGetContactDetails }) => {
    const { agentContactInfo, numbersShown } = useContext(AgentContactInfoContext);

    const getPhoneNumbers = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onGetContactDetails();
    }

    if (!numbersShown) {
        return (
            <button className="contact-info-button" onClick={getPhoneNumbers}>Show phone no.</button>
        );
    }
    else {
        if (agentContactInfo) {
            const { tel, cell } = agentContactInfo
            return (
                <div className="contact-info-container">
                    {
                        tel &&
                        (<div className="contact-info">
                            <span className="contact-info__label">Tel:</span>
                            <a className="contact-info__link" href={`tel:${tel}`}>{formatPhoneNumbers(cell)}</a>
                        </div>)
                    }
                    {
                        cell &&
                        (<div className="contact-info">
                            <span className="contact-info__label">Cell:</span>
                            <a className="contact-info__link" href={`tel:${cell}`}>{formatPhoneNumbers(cell)}</a>
                        </div>)
                    }
                </div>
            );
        }
        else {
            return (
                <div className="contact-info-error">
                    <p>Unable to retrieve contact info</p>
                </div>
            );
        }
    }
}

export default ContactInfoManager;