import axios from "axios";
import { IAjaxResponseBase } from '../utils/types';

export const postContactForm = (name: string, phoneNumber: string, email: string, message: string): Promise<IAjaxResponseBase> => {
    return axios.post("/Portal/ContactUs/ContactAgency/", {
        name,
        phoneNumber,
        email,
        message
    }).then(response => response.data);
}

export const postContactAgentForm = (agentId:number, name: string, phoneNumber: string, email: string, message: string): Promise<IAjaxResponseBase> => {
    return axios.post("/Portal/AgentProfile/ContactAgent/", {
        agentId,
        name,
        phoneNumber,
        email,
        message
    }).then(response => response.data);
}

export const postListingLeadForm = (listingId:number, name: string, phoneNumber: string, email: string, message: string): Promise<IAjaxResponseBase> => {
    return axios.post("/Portal/Listing/ListingLead/", {
        listingId,
        name,
        phoneNumber,
        email,
        message
    }).then(response => response.data);
}