import "./index.scss";

import { getDataAttribute } from "../../utils/portal-utils";

var globalRef = global as { [id: string] : any; };
if (globalRef === null){
    console.error("global index is not defined!!!");
}
const navBars: Array<Navbar> = globalRef["navBars"] || [];

const Selector = {
    toggle: `[data-toggle="collapse"]`,
    targetDataAttribute: "target",
    dropDown: ".dropdown",
    dropDownMenu: ".dropdown-menu",
    navItem: ".nav-item",
    navLink: ".nav-link",
}

const CssClasses = {
    show: "show",
    collapse: "collapse",
    in: "in",
    active: "active"
}

class Navbar {
    private _element: HTMLElement;
    private _dropdowns: Array<HTMLElement>;


    public toggle: HTMLElement | null;
    public collapse: HTMLElement | null;

    constructor(element: HTMLElement) {
        this._element = element;
        this.toggle = this._element.querySelector<HTMLElement>(Selector.toggle);
        if (this.toggle) {
            this.collapse = this._element.querySelector<HTMLElement>(getDataAttribute(this.toggle, Selector.targetDataAttribute));
        }
        else {
            this.collapse = null;
        }

        this._dropdowns = Array.from(this._element.querySelectorAll(Selector.dropDown));

        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.closeMenus = this.closeMenus.bind(this);

        if (this.toggle) {
            this.toggle.addEventListener("click", this.toggleMenu, false);
        }

        if (this._dropdowns.length) {
            this._dropdowns.forEach(dropdown => {
                const navLink = dropdown.querySelector<HTMLAnchorElement>(Selector.navLink);
                if (navLink) {
                    navLink.addEventListener("click", (e: MouseEvent) => {
                        if (navLink.hasAttribute("href") && navLink.getAttribute("href") === "#") {
                            e.preventDefault();
                        }
                        const shown = dropdown.classList.contains(CssClasses.show);
                        const dropDownMenu = dropdown.querySelector(Selector.dropDownMenu);
                        this.closeMenus();
                        if (!shown) {
                            dropdown.classList.add(CssClasses.show);
                            if (dropDownMenu) {
                                dropDownMenu.classList.add(CssClasses.show);
                                requestAnimationFrame(() => {
                                    document.addEventListener("click", this.handleClickOutside, false);
                                });
                            }

                        }
                        else {
                            dropdown.classList.remove(CssClasses.show);
                            if (dropDownMenu) {
                                dropDownMenu.classList.remove(CssClasses.show);
                                requestAnimationFrame(() => {
                                    document.removeEventListener("click", this.handleClickOutside, false);
                                });
                            }
                        }
                    })
                }

            })
        }

        this.setActiveItem();
    }

    setActiveItem() {
        const navItems = Array.from(this._element.querySelectorAll<HTMLElement>(Selector.navItem));
        navItems.forEach(navItem => {
            if (!navItem.classList.contains(Selector.dropDown.replace(".", ""))) {
                const navLink = navItem.querySelector<HTMLAnchorElement>("a");
                if (navLink) {
                    if (navLink.href === location.href) {
                        navItem.classList.add(CssClasses.active);
                    }
                    else {
                        navItem.classList.remove(CssClasses.active);
                    }
                }
            }
            else {
                const dropDownMenu = navItem.querySelector(Selector.dropDownMenu);
                if (dropDownMenu) {
                    const dropDownLinks = Array.from(dropDownMenu.querySelectorAll<HTMLAnchorElement>("a"));
                    dropDownLinks.forEach(anchor => {
                        if (anchor.href === location.href) {
                            anchor.classList.add(CssClasses.active);
                            navItem.classList.add(CssClasses.active);
                        }
                    });
                }
            }
        })
    }

    handleClickOutside(e: MouseEvent) {
        if (e) {
            let closeMenu = true;
            const currentTarget = e.target as HTMLElement;
            const openDropdowns = this._dropdowns.filter(dropdown => dropdown.classList.contains(CssClasses.show));
            for (let dropDown of openDropdowns) {
                if (dropDown.contains(currentTarget)) {
                    closeMenu = false;
                    break;
                }
            }
            if (closeMenu) {
                this.closeMenus();
            }
        }
    }

    static closeMenusOnResize() {
        if (document.body.clientWidth >= 768) {
            navBars.forEach(navbar => {
                navbar.closeMenus();
                const { collapse } = navbar;
                if (collapse) {
                    collapse.classList.add(CssClasses.collapse);
                    collapse.classList.remove(CssClasses.in);
                }
            });
        }
    }

    closeMenus() {
        const dropDowns = Array.from(document.querySelectorAll(Selector.dropDown));
        for (let dropDown of dropDowns) {
            const dropDownMenu = dropDown.querySelector(Selector.dropDownMenu);
            if (dropDownMenu) {
                dropDownMenu.classList.remove(CssClasses.show);
            }
            dropDown.classList.remove(CssClasses.show);
        }
    }

    toggleMenu() {
        if (this.collapse) {
            this.collapse.classList.toggle(CssClasses.collapse);
            this.collapse.classList.toggle(CssClasses.in);
        }
    }
}

export const initNavbars = (navbarSelector: string = ".navbar") => {
    const navbarElements = Array.from(document.querySelectorAll<HTMLElement>(navbarSelector));

    if (navbarElements && navbarElements.length) {
        navbarElements.forEach((navbar) => {
            navBars.push(new Navbar(navbar));
        });
    }
    // Event listeners
    window.addEventListener("resize", Navbar.closeMenusOnResize, false);
}