import React, { useState, useLayoutEffect } from "react";
import { Col, Row, Input, Container, FormGroup, Button, Label, ButtonGroup } from "reactstrap";
import Select from "react-select";
import { IShapeTree, IShape, PropertyTypes, PropertyTypesDescriptions, IFiltersTab } from '../../utils/types';
import queryString from "query-string";


import "./property-filters.scss";
import { formatCurrency } from '../../utils/portal-utils';



export interface IPropertyFilterProps {
    shapes: Array<IShapeTree>;
    submitUrl: string;
    listingTypeTabs: Array<IFiltersTab>;
    priceRanges: Array<number>;
    showFilters: boolean;
    showSalesRentals: boolean;
    propertyTypes: Array<PropertyTypes>;
}

export interface IAppliedFilters {
    selectedShapes: Array<number>;
    selectedPropertyTypes: Array<number>;
    minPrice: number;
    maxPrice: number;
    beds: number;
    baths: number;
    selectedSortOption: string;
}

interface SelectOptions {
    label: string;
    value: string | number;
    options?: Array<SelectOptions>;
}

const getSelectedShapes = (shapeTrees: Array<IShapeTree>, selectedShapes: Array<number>, result: Array<IShape>): Array<IShape> => {
    shapeTrees.forEach(st => {
        if (st.childShapes && st.childShapes.length > 0 && st.shape.id !== st.shape.parentId) {
            getSelectedShapes(st.childShapes, selectedShapes, result);
        }
        else {
            if (selectedShapes.indexOf(st.shape.id) >= 0) {
                result.push(st.shape);
            }
        }
    });
    return result;
}

const getShapeOptions = (shapeTree: Array<IShapeTree>): Array<SelectOptions> => {
    return shapeTree.map(st => {
        if (st.childShapes && st.childShapes.length > 0 && st.shape.id !== st.shape.parentId) {
            return {
                label: st.shape.name,
                value: st.shape.id,
                options: getShapeOptions(st.childShapes)
            }
        }
        else {
            return {
                label: st.shape.name,
                value: st.shape.id
            }
        }
    });
}

export const getAppliedFilters = (selectedShapes: Array<number>, selectedPropertyTypes: Array<number>, minPrice: number, maxPrice: number, beds: number, baths: number, selectedSortOption: string): Partial<IAppliedFilters> => {
    const appliedFilters: Partial<IAppliedFilters> = {};

    appliedFilters.selectedShapes = selectedShapes;

    appliedFilters.selectedPropertyTypes = selectedPropertyTypes;

    if (minPrice) {
        appliedFilters.minPrice = minPrice;
    }
    if (maxPrice) {
        appliedFilters.maxPrice = maxPrice;
    }
    if (beds) {
        appliedFilters.beds = beds;
    }
    if (baths) {
        appliedFilters.baths = baths;
    }
    if (selectedSortOption) {
        appliedFilters.selectedSortOption = selectedSortOption;
    }
    return appliedFilters;
}



const PropertyFilters: React.FC<IPropertyFilterProps & IAppliedFilters> = (props) => {
    const { shapes, showFilters, propertyTypes, submitUrl, priceRanges, selectedSortOption } = props;

    const mql = window.matchMedia("(min-width:768px)");

    const mediaQueryListener = (e: MediaQueryListEvent | MediaQueryList) => {
        if (e.matches) {
            setHideFilters(false);
        }
        else {
            setHideFilters(true);
        }
    };

    useLayoutEffect(() => {
        mql.addListener(mediaQueryListener);
        mediaQueryListener(mql);
        return () => {
            mql.removeListener(mediaQueryListener as any);
        }
    }, []);

    const [selectedShapes, setSelectedShapes] = useState<Array<IShape>>(getSelectedShapes(shapes, props.selectedShapes, []));
    const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(props.selectedPropertyTypes);
    const [minPrice, setMinPriceState] = useState(props.minPrice);
    const [maxPrice, setMaxPriceState] = useState(props.maxPrice);
    const [beds, setBeds] = useState(props.beds);
    const [baths, setBaths] = useState(props.baths);
    const [hideFilters, setHideFilters] = useState(!props.showFilters);

    const propertyTypeOptions: Array<SelectOptions> = propertyTypes
        .map(type => {
            const label = PropertyTypesDescriptions.get(type) || type.toString();
            return {
                value: type,
                label
            };
        });



    const submitFilters = () => {
        const filters = getAppliedFilters(selectedShapes.map(s => s.id), selectedPropertyTypes, minPrice, maxPrice, beds, baths, selectedSortOption);
        const filtersQuery = queryString.stringify(filters, { arrayFormat: "comma" });
        if (filtersQuery) {
            location.href = `${submitUrl}?${filtersQuery}`;
        }
        else {
            if (location.pathname === submitUrl && location.search) {
                location.href = submitUrl;
            }
            else if (location.pathname !== submitUrl) {
                location.href = submitUrl;
            }
        }
    }

    const setMinPrice = (price: number) => {
        if (price >= maxPrice && maxPrice > 0) {
            const minPriceIndex = priceRanges.findIndex(p => p === price);
            if (minPriceIndex === priceRanges.length - 1) {
                setMaxPriceState(0);
            }
            else {
                setMaxPriceState(priceRanges[minPriceIndex + 1]);
            }
        }
        setMinPriceState(price);
    }

    const setMaxPrice = (price: number) => {
        if (price) {
            if (price <= minPrice) {
                const maxPriceIndex = priceRanges.findIndex(p => p === price);
                if (maxPriceIndex > 0) {
                    setMinPriceState(priceRanges[maxPriceIndex - 1]);
                }
                else {
                    setMinPriceState(0);
                }
            }
        }
        setMaxPriceState(price);
    }

    return (
        <Container className="propertyFilters">
            <Row>
                {
                    props.showSalesRentals &&
                    <Col sm={12} md={3} lg={2}>
                        <div className="propertyFilters_listingType">
                            <ButtonGroup>
                                {
                                    props.listingTypeTabs.map(tab => {
                                        return (<Button key={tab.url} color="secondary" onClick={() => location.href = tab.url} active={tab.active}>{tab.linkText}</Button>);
                                    })
                                }
                            </ButtonGroup>
                        </div>
                    </Col>
                }
                <Col sm={12} md={5} lg={6}>
                    <FormGroup>
                        <Label>Suburbs</Label>
                        <Select
                            value={selectedShapes.map(s => ({ label: s.name, value: s.id }))}
                            isMulti
                            options={getShapeOptions(shapes)}
                            onChange={(value, action) => {
                                const shapeIds: number[] = (value as any).map((s: { value: number, label: string }) => s.value);
                                setSelectedShapes(getSelectedShapes(shapes, shapeIds, []));
                            }}
                        />
                    </FormGroup>
                    {
                        showFilters &&
                        <div className="propertyFilters_moreFilters">
                            <label>More filters</label>
                            <button className="propertyFilters_filtersToggle" onClick={() => setHideFilters(!hideFilters)}>+</button>
                        </div>
                    }
                </Col>
                {
                    showFilters && !hideFilters &&
                    <Col sm={12} md={4}>
                        <FormGroup>
                            <Label>Property Types</Label>
                            <Select
                                value={selectedPropertyTypes.map(pt => {
                                    return {
                                        value: pt,
                                        label: PropertyTypesDescriptions.get(pt) || PropertyTypes[pt]
                                    }
                                })}
                                isMulti
                                options={propertyTypeOptions}
                                onChange={(value, action) => {
                                    const selectedValues = (value as any).map((v: { value: number, label: string }) => v.value);
                                    setSelectedPropertyTypes(selectedValues);
                                }}
                            />
                        </FormGroup>
                    </Col>
                }
            </Row>
            <Row>
                {
                    showFilters && !hideFilters &&
                    <React.Fragment>
                        <Col sm={12} md={2}>
                            <Label>Min Price</Label>
                            <Input type="select" value={minPrice} onChange={(e) => setMinPrice(parseInt(e.target.value))}>
                                <option value={0}>Any</option>
                                {
                                    priceRanges.map(price => {
                                        return (<option key={price} value={price}>{formatCurrency(price)}</option>)
                                    })
                                }
                            </Input>
                        </Col>
                        <Col sm={12} md={2}>
                            <FormGroup>
                                <Label>Max Price</Label>
                                <Input type="select" value={maxPrice} onChange={(e) => setMaxPrice(parseInt(e.target.value))}>
                                    <option value={0}>Any</option>
                                    {
                                        priceRanges.map(price => {
                                            return (<option key={price} value={price}>{formatCurrency(price)}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={2}>
                            <FormGroup>
                                <Label>Beds</Label>
                                <Input type="select" value={beds} onChange={(e) => setBeds(parseInt(e.target.value))}>
                                    <option value={0}>Any</option>
                                    <option value={1}>1+</option>
                                    <option value={2}>2+</option>
                                    <option value={3}>3+</option>
                                    <option value={4}>4+</option>
                                    <option value={5}>5+</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={2}>
                            <FormGroup>
                                <Label>Baths</Label>
                                <Input type="select" value={baths} onChange={(e) => setBaths(parseInt(e.target.value))}>
                                    <option value={0}>Any</option>
                                    <option value={1}>1+</option>
                                    <option value={2}>2+</option>
                                    <option value={3}>3+</option>
                                    <option value={4}>4+</option>
                                    <option value={5}>5+</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </React.Fragment>
                }
                <Col sm={12} md={{ size: 4 }}>
                    <Button size="sm" block color="default" className="btn_fill propertyFilters_submit" onClick={submitFilters}>Search</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default PropertyFilters;