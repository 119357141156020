export enum ListingType {
    Sales,
    Rentals
}

export enum ListingSlice {
    Residential,
    ResidentialOnShow,
    ResidentialDevelopments,
    Commercial,
    Farms,
}

export enum SortOptions {
    Newest,
    PriceHighToLow,
    PriceLowToHigh,
}

export enum ListingFlags {
    New,                    // Sale
    OfferMade,              // Sale / Rental
    PriceReduced,           // Sale / Rental
    Sold,                   // Sale
    AvailableImmediately,   // Rental
    Leased                  // Rental
}

export interface IShape {
    id: number;
    name: string;
    parentId: number;
}

export interface IShapeTree {
    shape: IShape;
    childShapes?: Array<IShapeTree>;
}

export const ListingSliceDescriptions = new Map<ListingSlice, string>([
    [ListingSlice.Residential, "Residential"],
    [ListingSlice.ResidentialOnShow, "On Show"],
    [ListingSlice.ResidentialDevelopments, "Developments"],
    [ListingSlice.Commercial, "Commercial"],
    [ListingSlice.Farms, "Farms"]
]);

export enum PropertyTypes {
    Houses,
    FlatsAndApartments,
    TownHousesAndClusters,
    Land,
    FarmsAndSmallHoldings,
    GardenCottage
}

export const PropertyTypesDescriptions = new Map<PropertyTypes, string>([
    [PropertyTypes.FarmsAndSmallHoldings, "Farms & Smallholdings"],
    [PropertyTypes.FlatsAndApartments, "Flats & Apartments"],
    [PropertyTypes.Houses, "Houses"],
    [PropertyTypes.Land, "Land"],
    [PropertyTypes.TownHousesAndClusters, "Townhouses & Clusters"],
    [PropertyTypes.GardenCottage, "Garden Cottage"]
]);

export interface IFiltersTab {
    linkText: string;
    url: string;
    active: boolean;
}

export interface IResponsiveImage {
    desktopUrl: string;
    mobileUrl: string;
    identifier: string;
}

export interface IFiltersContext {
    shapeOptions: Array<IShape>;
    submitUrl: string;
    listingTypeTabs: Array<IFiltersTab>;
    showFilters: boolean;
    showSalesRentals: boolean;
    priceRanges: Array<number>;
    propertyTypes: Array<PropertyTypes>;
}

export interface IListingContext {
    listingId: number;
    galleryImages: Array<IResponsiveImage>;
    themeColor: string;
    agentIds: Array<number>;
}

export interface IAgentContext {
    agentId: number;
}

export interface IClienContext {
    pageName: string;
    filtersContext?: IFiltersContext;
    listingContext?: IListingContext;
    agentContext?: IAgentContext;
}

export interface IAjaxResponseBase {
    success: boolean;
    message: string;
    serverErrors: Array<string>;
}