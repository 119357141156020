import React, { useState } from "react";
import ReactDOM from "react-dom";


import ContactInfoManager from "./contact-info-manager";
import AgentContactInfoContext, { IAgentContactInfo } from "./agent-contact-info-context";

import "./agent-contact-card.scss";
import { getListingContactDetails } from '../../../server-api/contact-details';

interface IAgentContactCardsProps {
    agentIds: Array<number>;
    contactCardsContainer: HTMLElement;
}

const AgentContactCards: React.FC<IAgentContactCardsProps> = ({ agentIds, contactCardsContainer }) => {
    const [allAgentsContactInfo, setAllAgentsContactInfo] = useState<Array<IAgentContactInfo>>([]);
    const currentAgentIndex = 0;
    const [numbersShown, setNumbersShown] = useState(false);

    const handleGetContactDetails = () => {
        getListingContactDetails(agentIds).then(response => {
            if (response.success) {
                setAllAgentsContactInfo(response.listingContactNumbers.map(contactDetails => ({
                    agentId: contactDetails.agentId,
                    cell: contactDetails.cell,
                    tel: contactDetails.tel
                })));
                setNumbersShown(true);
            }
        }).catch(err => {
            setNumbersShown(false);
            console.log(err);
        })
    }

    const getCurrentContactDetails = (agentIndex: number) => {
        if (allAgentsContactInfo && allAgentsContactInfo.length > 0) {
            return allAgentsContactInfo[agentIndex];
        } else {
            return null;
        }
    }

    return (
        <AgentContactInfoContext.Provider value={{
            agentContactInfo: getCurrentContactDetails(currentAgentIndex),
            numbersShown
        }}>
            <ContactInfoManager onGetContactDetails={handleGetContactDetails} />
        </AgentContactInfoContext.Provider>
    )
}

const initializeAgentContactCards = (contactCardsContainer: HTMLElement, contactInfoContainer: HTMLElement, agentIds: Array<number>) => {

    ReactDOM.render(
        <AgentContactCards
            agentIds={agentIds}
            contactCardsContainer={contactCardsContainer} />,
        contactInfoContainer);

};

export default initializeAgentContactCards;