export const parseQueryStringArray = (parameterValue: string | (string | null)[] | null): string[] => {
    if (parameterValue) {
        if (typeof parameterValue === "string") {
            return [parameterValue];
        }
        else if (parameterValue === null){
            return []
        }
        else {
            return parameterValue?.filter((v) => v !== null).map(v => v as string)
        }
    }
    return [];
}
