import axios from "axios";
import { IAjaxResponseBase } from '../utils/types';


interface IListingContactNumbers {
    agentId: number;
    tel: string;
    cell: string;
}

interface IListingContactNumbersResponse extends IAjaxResponseBase {
    listingContactNumbers: Array<IListingContactNumbers>;
}

export const getListingContactDetails = async (agentIds: Array<number>):Promise<IListingContactNumbersResponse> => {
    try {
        const response = await axios.get<IListingContactNumbersResponse>(`/portal/listing/listingcontactdetails?agentIds=${agentIds.join(",")}`);
        const { data } = await response;
        return data;
    } catch (e) {
        console.log(e);
        throw new Error(`Error retrieving contact numbers`);
    }
    
}