import "./paragraph-expander.scss";

export interface IParagraphExpanderProps {
    buttonSelector: string;
    paragraphSelector: string;
    collapsedHeight: number;
}

const ParagraphExpander = (expander: HTMLElement, options?: Partial<IParagraphExpanderProps>) => {
    const defaults: Partial<IParagraphExpanderProps> = {
        buttonSelector: "#readMore",
        paragraphSelector: "#descriptionParagraph",
        collapsedHeight: 200,
    };

    const props = {
        ...defaults,
        ...options
    };

    if (!expander.classList.contains) {
        expander.classList.add("collapsed")
    }

    let button = expander.querySelector<HTMLElement>("a");
    if (props.buttonSelector) {
        button = document.querySelector<HTMLElement>(props.buttonSelector);
    }

    if (button) {
        let paragraph: HTMLElement | null = expander.querySelector("p");

        if (props.paragraphSelector) {
            paragraph = document.querySelector<HTMLElement>(props.paragraphSelector);
        }

        const expandParagraph = () => {
            expander.classList.remove("collapsed");
            expander.classList.add("expanded");
            if (button) {
                button.style.display = "none";
                button.removeEventListener("click", expandParagraph);
            }
        }
        if (paragraph && paragraph.clientHeight > expander.clientHeight) {
            button.addEventListener("click", expandParagraph);
        }
        else {
            button.style.display = "none";
            expander.classList.remove("collapsed");
            expander.classList.add("expanded");
        }

    }

}
export default ParagraphExpander;