import React, { useState, useContext } from "react";
import { Button, Alert, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { FormPageContext, IFormPageContext, IFormValues } from "./context-provider";

import "./contact-form.scss";

interface ContactFormProps {
    classNames?: string
}

const ContactForm: React.FC<ContactFormProps> = (props) => {

    const { onFormSubmit, serverErrors, submitted, initialValues, successMessage } = useContext<IFormPageContext>(FormPageContext);

    const [name, setName] = useState(initialValues.name);

    const [phone, setPhone] = useState(initialValues.phone);

    const [email, setEmail] = useState(initialValues.email);

    const [message, setMessage] = useState(initialValues.message);


    const handleValidSubmit = (e: Event, values: IFormValues) => {
        onFormSubmit(values);
    }

    if (submitted) {
        return (
            <Alert color="success">
                {successMessage}
            </Alert>
        )
    }
    return (
        <AvForm className={props.classNames} onValidSubmit={handleValidSubmit}>
            {
                serverErrors.length > 0 &&
                <Alert color="danger">
                    {
                        serverErrors.map((error, i) => {
                            return (<p key={i}>{error}</p>);
                        })
                    }
                </Alert>
            }
            <Col md={12}>
                <AvField
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    errorMessage="Name is required"
                    required />
            </Col>
            <Col md={12}>
                <AvField
                    name="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={phone}
                    pattern="[0-9]{10}"
                    onChange={(e) => setPhone(e.target.value)} />
            </Col>
            <Col md={12}>
                <AvField
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errorMessage="Email is required"
                    validate={{
                        required: { value: true, errorMessage: "Email is required" },
                        email: { value: true, errorMessage: "Email is invalid" }
                    }} />
            </Col>
            <Col md={12}>
                <AvField
                    name="message"
                    type="textarea"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)} />
            </Col>
            <Col>
                <Button color="default" className="btn_fill">Send Message</Button>
            </Col>
        </AvForm>
    );
}

export default ContactForm;