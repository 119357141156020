import React from "react";
import ReactDOM from "react-dom";

import FormPageContextProvider, { IFormValues } from "../components/forms/context-provider";
import ContactForm from "../components/forms/contact-form";
import { IAjaxResponseBase } from '../utils/types';
import { postContactAgentForm } from "../server-api/contact-us";

interface PageProps {
    initialValues: IFormValues;
    agentId: number;
}

export const initAgentProfilePage = (pageProps: PageProps) => {
    const contactForm = document.querySelector("#contactForm");
    if (contactForm) {
        ReactDOM.render(<AgentProfilePage initialValues={pageProps.initialValues} agentId={pageProps.agentId} />, contactForm);
    }
}

const onFormSubmit = ({ email, message, name, phone }: IFormValues, agentId: number): Promise<IAjaxResponseBase> => {
    return postContactAgentForm(agentId, name, phone, email, message);
}

const AgentProfilePage: React.FC<PageProps> = (props) => {
    return (
        <FormPageContextProvider initialValues={props.initialValues} onSubmit={(formValues) => onFormSubmit(formValues, props.agentId)}>
            <ContactForm />
        </FormPageContextProvider>
    )
}