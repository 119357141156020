import React from "react";
import ReactDOM from "react-dom";

import FormPageContextProvider, { IFormValues } from "../components/forms/context-provider";
import ContactForm from "../components/forms/contact-form";
import { IAjaxResponseBase } from '../utils/types';
import { postContactForm } from '../server-api/contact-us';

interface PageProps {
    initialValues: IFormValues;
}

const onFormSubmit = ({ name, email, message, phone }: IFormValues): Promise<IAjaxResponseBase> => {
    return postContactForm(name, phone, email, message);
}

export const initContactUsPage = (pageParams: PageProps) => {
    const formContainer = document.querySelector("#formContainer");
    if (formContainer) {
        const { initialValues } = pageParams
        ReactDOM.render(
            <ContactUsPage initialValues={initialValues} />
            , formContainer);
    }
}

const ContactUsPage: React.FC<PageProps> = (props) => {

    return (
        <FormPageContextProvider initialValues={props.initialValues} onSubmit={onFormSubmit}>
            <ContactForm classNames="callus padding-bottom" />
        </FormPageContextProvider>

    );
}

