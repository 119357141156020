import "@babel/polyfill";

import React from "react";
import ReactDOM from 'react-dom';
import queryString, { ParsedQuery } from "query-string";
import cssVars from "css-vars-ponyfill";

import { initNavbars } from "./components/navbar";
import { initHomePage } from "./pages/homepage";
import { initContactUsPage } from "./pages/contact-us";

import "./index.scss";
import "./pages/search-results.scss";
import "./components/cards/listing-result.scss";
import "./components/page-elements/breadcrumbs.scss";
import "./components/page-elements/our-experts-list.scss";
import "./pages/privacy-policy.scss";

import { initAgentProfilePage } from './pages/agent-profile';
import { initLazyLoading } from './utils/lazyloader';
import initResultsPage from './pages/search-results';
import { IShapeTree, IClienContext, IFiltersContext, IShape } from './utils/types/index';
import initListingPage from './pages/listing-page';


import PropertyFilters, { IAppliedFilters } from './components/filters/property-filters';
import { getDataAttribute } from './utils/portal-utils';
import { parseQueryStringArray } from './utils/query-string-array-utils';

declare const clientContext: IClienContext;

const buildShapeTree = (shapeOptions: Array<IShape>): Array<IShapeTree> => {
    const rootShapes = shapeOptions.filter(s => s.parentId === -1);
    if (rootShapes && rootShapes.length > 0) {
        return rootShapes.map(s => {
            return {
                shape: s,
                childShapes: getChildShapes(s.id, shapeOptions)
            }
        })
    }
    console.error("Unable to locate root shapes");
    return [{
        shape: {
            id: -1,
            name: "",
            parentId: -1
        },
        childShapes: []
    }];
}

const getChildShapes = (parentId: number, shapeOptions: Array<IShape>): Array<IShapeTree> => {
    const childShapes = shapeOptions.filter(s => s.parentId === parentId);
    if (childShapes) {
        return childShapes.map(s => {
            return {
                shape: s,
                childShapes: getChildShapes(s.id, shapeOptions)
            }
        })
    }
    return [];
}

const initPortal = () => {

    // Treat Edge 15/16 as legacy
    const onlyLegacy = !(/Edge\/1[56]\./i.test(navigator.userAgent));
    cssVars({
        onlyLegacy
    });

    initLazyLoading({ logErrors: false });

    initNavbars();

    const { query } = queryString.parseUrl(location.href, {
        arrayFormat: "comma"
    });
    if (typeof clientContext !== "undefined") {
        const { pageName, filtersContext, listingContext, agentContext } = clientContext;
        const filters: IAppliedFilters = parseFilters(query);
        if (filtersContext) {
            initFilters(filtersContext, filters);
        }

        if (typeof pageName !== "undefined") {
            switch (pageName) {
                case "listingDetails":
                    initListingPage(listingContext);
                case "listingResults":
                    initResultsPage({ appliedFilters: filters });
                    break;
                case "contactUs":
                    initContactUsPage({ initialValues: { name: "", phone: "", email: "", message: "" } });
                    //await import(/* webpackChunkName: "contact-us" */ "./pages/contact-us").then(contactUs => {
                    //    contactUs.initContactUsPage();
                    //});
                    break;
                case "agentProfile":
                    if (agentContext) {
                        initAgentProfilePage({ initialValues: { name: "", phone: "", email: "", message: "" }, agentId: agentContext.agentId });
                    }
                    break;
                case "homePage":
                default:
                    initHomePage();
                    //await import(/* webpackChunkName: "homepage" */ "./pages/homepage").then((homePage) => {
                    //    homePage.initHomePage();
                    //});
                    break;
            }
        }
    }
}

const togglePropertyTabs = (nav: HTMLElement) => {
    nav.classList.toggle("collapse");
}

const initFiltersNav = () => {
    const navToggle = document.querySelector<HTMLButtonElement>("#propertyTypesToggle");
    if (navToggle) {
        const navSelector = getDataAttribute(navToggle, "target");
        if (navSelector) {
            const nav = document.querySelector<HTMLElement>(navSelector);
            if (nav) {
                const mql = window.matchMedia("(max-width:767px)");
                if (mql.matches) {
                    if (!nav.classList.contains("collapse")) {
                        togglePropertyTabs(nav);
                    }
                }
                else {
                    if (nav.classList.contains("collapse")) {
                        togglePropertyTabs(nav);
                    }
                }
                mql.addListener((e: MediaQueryListEvent) => {
                    if (e.matches) {
                        if (!nav.classList.contains("collapse")) {
                            togglePropertyTabs(nav);
                        }
                    }
                    else {
                        if (nav.classList.contains("collapse")) {
                            togglePropertyTabs(nav);
                        }
                    }
                });

                navToggle.addEventListener("click", () => {
                    togglePropertyTabs(nav);
                });
            }
        }
    }
}

const initFilters = (filtersContext: IFiltersContext, filters: IAppliedFilters) => {
    initFiltersNav();
    const filtersContainer = document.getElementById("filters");
    if (filtersContainer) {
        const { listingTypeTabs, showFilters, priceRanges, submitUrl, propertyTypes, showSalesRentals, shapeOptions } = filtersContext;

        const shapesTree = buildShapeTree(shapeOptions);

        const { selectedShapes, selectedPropertyTypes, beds, baths, maxPrice, minPrice, selectedSortOption } = filters;
        ReactDOM.render(<PropertyFilters
            listingTypeTabs={listingTypeTabs}
            showFilters={showFilters}
            showSalesRentals={showSalesRentals}
            priceRanges={priceRanges}
            submitUrl={submitUrl}
            selectedShapes={selectedShapes}
            selectedPropertyTypes={selectedPropertyTypes}
            beds={beds}
            baths={baths}
            maxPrice={maxPrice}
            minPrice={minPrice}
            shapes={shapesTree}
            propertyTypes={propertyTypes}
            selectedSortOption={selectedSortOption}
        />, filtersContainer);
    }
}

const parseFilters = (query: ParsedQuery): IAppliedFilters => {
    const appliedFilters: IAppliedFilters =
    {
        baths: 0,
        beds: 0,
        maxPrice: 0,
        minPrice: 0,
        selectedPropertyTypes: [],
        selectedShapes: [],
        selectedSortOption: ""
    }
    appliedFilters.selectedPropertyTypes = parseQueryStringArray(query.selectedPropertyTypes).map(p => parseInt(p));

    appliedFilters.selectedShapes = parseQueryStringArray(query.selectedShapes).map(s => parseInt(s));

    if (query.maxPrice) {
        appliedFilters.maxPrice = parseInt(query.maxPrice as string);
    }
    if (query.minPrice) {
        appliedFilters.minPrice = parseInt(query.minPrice as string);
    }
    if (query.beds) {
        appliedFilters.beds = parseInt(query.beds as string);
    }
    if (query.baths) {
        appliedFilters.baths = parseInt(query.baths as string);
    }
    if (query.selectedSortOption) {
        appliedFilters.selectedSortOption = query.selectedSortOption as string;
    }

    return appliedFilters
}

document.addEventListener("DOMContentLoaded", initPortal);